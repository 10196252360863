import Link from "next/link";
import { useRouter } from 'next/router'

const Hero_4 = () => {

  const failed = useRouter()?.query?.failed ?? null
  const username = useRouter()?.query?.username ?? "to a new world";
  const created = useRouter()?.query?.created ?? null;

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <img
            className="h-full w-full"
            src="/images/gradient.jpg"
            alt="gradient"
          />
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <img
            className="h-full w-full"
            src="/images/gradient_dark.jpg"
            alt="gradient dark"
          />
        </picture>

        <div className="container">
          <div className="mx-auto max-w-3xl pt-24 text-center">
            <h1 className="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
              {
                (failed !== null) ? (<>
                  Something went wrong 😖
                </>) : (<>
                  Success! 🎉 Welcome,<br />
                  <span className="animate-gradient"> {username} </span>
                </>)
              }

            </h1>
          </div>
          {
            (created !== null) && <h1></h1>
          }
          {
            (failed !== null) ? (<>
              Try to reconnect mtion studio again.  Sometimes givng it another try works.
            </>) : (<>

            </>)
          }


        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_4;
