import { useState, useEffect, useRef, useMemo } from 'react';
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useAnimationFrame,
  useTransform,
} from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';

const experiences = [
    '/images/experiences/Untitled design (20).png',
    '/images/experiences/Untitled design (21).png',
    '/images/experiences/Untitled design (22).png',
    '/images/experiences/Untitled design (23).png',
    '/images/experiences/Untitled design (24).png',
    '/images/experiences/Untitled design (25).png',
    '/images/experiences/Untitled design (26).png',
    '/images/experiences/Untitled design (27).png',
    '/images/experiences/Void.png',
    '/images/experiences/Voxid1-min.png',
    '/images/experiences/Voxid2-min.png',
    '/images/experiences/Wonzy3.png',
    '/images/experiences/Wonzy4-min.png',
    '/images/experiences/Xaon2.jpg',
    '/images/experiences/Xaon3.jpg',
    '/images/experiences/Xoan.jpg',
    '/images/experiences/1-Avatar.png',
    '/images/experiences/1-Bar2.jpg',
    '/images/experiences/1-Cafe_mislakane.png',
    '/images/experiences/1-Chai_1-min.png',
    '/images/experiences/1-Cyberpunk bar-min.png',
    '/images/experiences/1-Desert tower2-min.png',
    '/images/experiences/1-Desert tower-min.png',
    '/images/experiences/1-Dooper 6-min.png',
    '/images/experiences/1-Dooper1-min.png',
    '/images/experiences/1-Exvarn2-min.png',
    '/images/experiences/1-Fighting arena 2-min.png',
    '/images/experiences/1-Friends hangout-min.png',
    '/images/experiences/1-Gamer room1-min.png',
    '/images/experiences/1-Kongo3.jpg',
    '/images/experiences/1-Liliroux.jpg',
    '/images/experiences/1-Pepermintea-min.png',
    '/images/experiences/1-Role Play-min.png',
    '/images/experiences/1-Tree house1-min.png',
    '/images/experiences/1-Voxid3-min.png',
    '/images/experiences/1-Wonzy1.png',
    '/images/experiences/1-Wonzy2.png',
    '/images/experiences/1-Xaon4-min.png',
    '/images/experiences/Arcade-min.png',
    '/images/experiences/Buildathon1.jpg',
    '/images/experiences/Cafe.jpg',
    '/images/experiences/Castle.jpg',
    '/images/experiences/Chai_2-min.png',
    '/images/experiences/D&D setup.jpg',
    '/images/experiences/Desert tower3.jpg',
    '/images/experiences/Dooper 5-min.png',
    '/images/experiences/Dooper2-min.png',
    '/images/experiences/Dooper3-min.png',
    '/images/experiences/Dooper4-min.png',
    '/images/experiences/Dooper7-min.png',
    '/images/experiences/Exvarn1.png',
    '/images/experiences/Fighting arena-min.png',
    '/images/experiences/Fridgetbytes treehouse2-min.png',
    '/images/experiences/Gamer room2-min.png',
    '/images/experiences/Kongo1-min.png',
    '/images/experiences/Kongo2-min.png',
    '/images/experiences/Maze-min.png',
    '/images/experiences/Mislakane.png',
    '/images/experiences/Movie theater.png',
    '/images/experiences/Neon-min.png',
    '/images/experiences/Night club 1.jpg',
    '/images/experiences/Outskirt Arcade-min.png',
    '/images/experiences/Platformer1-2.jpg',
    '/images/experiences/Platformer2.jpg',
    '/images/experiences/Platformer3.jpg',
    '/images/experiences/Portal.jpg',
    '/images/experiences/Reading corner-min.png',
    '/images/experiences/Spaceship1-min.png',
    '/images/experiences/Statue-min.png',
    '/images/experiences/Steampunk-min.png',
    '/images/experiences/Talkshow stage.png',
    '/images/experiences/tiny house 1.jpg',
    '/images/experiences/Treehouse2.jpg',
    '/images/experiences/Untitled design (6).png',
    '/images/experiences/Untitled design (7).png',
    '/images/experiences/Untitled design (8).png',
    '/images/experiences/Untitled design (9).png',
    '/images/experiences/Untitled design (10).png',
    '/images/experiences/Untitled design (11).png',
    '/images/experiences/Untitled design (12).png',
    '/images/experiences/Untitled design (13).png',
    '/images/experiences/Untitled design (14).png',
    '/images/experiences/Untitled design (15).png',
    '/images/experiences/Untitled design (16).png',
    '/images/experiences/Untitled design (17).png',
    '/images/experiences/Untitled design (18).png',
    '/images/experiences/Untitled design (19).png'
];

const TIMEOUT_FLOOR_MS = 10000;
const TIMEOUT_RAND_MS = 20000;

const GridImage = ({ imageIndex, experiences, wavePhase, time }) => {
  const amplitudeY = 10; // Adjust as needed
  const amplitudeScale = 0.05; // Adjust as needed
  const frequency = (2 * Math.PI) / 7; // Wave period of 7 seconds

  const y = useTransform(time, (t) => {
    return amplitudeY * Math.sin(frequency * t - wavePhase);
  });

  const scale = useTransform(time, (t) => {
    return 0.9 + amplitudeScale * Math.sin(frequency * t - wavePhase);
  });

  const imageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 5 } },
    exit: { opacity: 0, transition: { duration: 5 } },
  };

  return (
    <AnimatePresence mode="sync">
      <motion.div
        key={imageIndex}
        variants={imageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{
          y,
          scale,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div className="relative w-full h-full">
          <Image
            src={experiences[imageIndex]}
            alt="Experience"
            layout="fill" // Use 'layout' prop for older Next.js versions
            objectFit="cover" // Pass 'objectFit' directly as a prop
            className="rounded-lg"
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const Hero_new = () => {
  const router = useRouter();
  const time = useMotionValue(0);

  // Detect window size for responsiveness
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1024
  );
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 768
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  // Adjust number of columns and rows based on window width
  const [numColumns, setNumColumns] = useState(6);
  const [numRows, setNumRows] = useState(5);

  useEffect(() => {
    let columns = 6;
    let rows = 5;
    if (windowWidth < 640) {
      // Small screens
      columns = 2;
      rows = 5;
    } else if (windowWidth < 1024) {
      // Medium screens
      columns = 4;
      rows = 5;
    } else {
      // Large screens
      columns = 6;
      rows = 5;
    }
    setNumColumns(columns);
    setNumRows(rows);
  }, [windowWidth]);

  useAnimationFrame((t) => {
    time.set(t / 1000); // Convert milliseconds to seconds
  });

  const [gridImages, setGridImages] = useState([]);
  const timeoutsRef = useRef([]);

  // Helper function to check for adjacent duplicates
  function hasAdjacentDuplicates(gridImages, numColumns, numRows) {
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numColumns; col++) {
        const idx = row * numColumns + col;
        const currentImage = gridImages[idx];

        // Check right neighbor
        if (col < numColumns - 1) {
          const rightIdx = idx + 1;
          if (gridImages[rightIdx] === currentImage) {
            return true;
          }
        }

        // Check down neighbor
        if (row < numRows - 1) {
          const downIdx = idx + numColumns;
          if (gridImages[downIdx] === currentImage) {
            return true;
          }
        }
      }
    }
    return false;
  }

  // Initialize grid images
  useEffect(() => {
    const totalCells = numColumns * numRows;
    const experienceIndices = experiences.map((_, idx) => idx);

    let initialGridImages = [];
    let attempts = 0;
    let maxAttempts = 1000;
    let foundValidArrangement = false;

    while (attempts < maxAttempts && !foundValidArrangement) {
      // Shuffle the experienceIndices
      const shuffledIndices = [...experienceIndices];
      for (let i = shuffledIndices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledIndices[i], shuffledIndices[j]] = [shuffledIndices[j], shuffledIndices[i]];
      }

      // Repeat the shuffled indices to cover all grid cells
      initialGridImages = [];
      while (initialGridImages.length < totalCells) {
        initialGridImages = initialGridImages.concat(shuffledIndices);
      }
      // Trim to totalCells
      initialGridImages = initialGridImages.slice(0, totalCells);

      if (!hasAdjacentDuplicates(initialGridImages, numColumns, numRows)) {
        foundValidArrangement = true;
        setGridImages(initialGridImages);
      } else {
        attempts++;
      }
    }

    if (!foundValidArrangement) {
      console.warn('Could not find a valid arrangement without adjacent duplicates');
      setGridImages(initialGridImages);
    }
  }, [numColumns, numRows]);

  // Manage image refresh timers
  useEffect(() => {
    if (gridImages.length === 0) return;

    // Cleanup previous timeouts
    timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
    timeoutsRef.current = [];

    // For each grid cell, set up a timeout to change the image
    gridImages.forEach((_, idx) => {
      const duration = Math.random() * TIMEOUT_RAND_MS + TIMEOUT_FLOOR_MS; // Random duration between 10 and 30 seconds
      const timeout = setTimeout(() => {
        updateGridImage(idx);
      }, duration);
      timeoutsRef.current.push(timeout);
    });

    return () => {
      // Clean up timeouts on unmount
      timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
    };
  }, [gridImages]);

  function updateGridImage(idx) {
    setGridImages(prevGridImages => {
      const prevImageIndex = prevGridImages[idx];

      // Build imagesUsed from prevGridImages
      const imagesUsed = new Set(prevGridImages);

      // Remove the image that we're changing
      imagesUsed.delete(prevImageIndex);

      // If all images have been used, reset imagesUsed
      if (imagesUsed.size === experiences.length - 1) {
        imagesUsed.clear();
      }

      // Create a list of available images
      const availableImages = experiences
        .map((_, index) => index)
        .filter(index => !imagesUsed.has(index));

      // Pick a random image from available images
      const newImageIndex = availableImages[Math.floor(Math.random() * availableImages.length)];

      // Update gridImages
      const newGridImages = [...prevGridImages];
      newGridImages[idx] = newImageIndex;
      return newGridImages;
    });

    // Set up the next timeout for this grid cell
    const duration = Math.random() * TIMEOUT_RAND_MS + TIMEOUT_FLOOR_MS; // Random duration between 10 and 30 seconds
    const timeout = setTimeout(() => {
      updateGridImage(idx);
    }, duration);
    timeoutsRef.current[idx] = timeout;
  }

  // Compute grid cells
  const gridCells = useMemo(() => {
    const period = 5; // 5 seconds wave period
    const frequency = (2 * Math.PI) / period;
    const colOffset = (2 * Math.PI) / numColumns;
    const rowOffset = (2 * Math.PI) / numRows;

    const cells = [];
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numColumns; col++) {
        const wavePhase = col * colOffset + (row / numRows) * rowOffset;
        cells.push({ wavePhase });
      }
    }
    return cells;
  }, [numColumns, numRows]);

  // Detect if on mobile device
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    // Simple check for mobile devices
    const mobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

    setIsMobile(mobile);
  }, []);

  return (
    <section
      id="hero-section"
      className="relative pb-[100px] pt-20 md:pt-28 overflow-visible"
    >
      {/* Background image */}
      <div className="pointer-events-none absolute inset-0 -z-10">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient"
          className="h-full w-full object-cover"
        />
      </div>

      <div className="min-h-screen bg-transparent text-white flex content-start justify-center p-4 overflow-hidden relative">
        {/* Main content */}
        <div className="z-10 bg-transparent p-0 flex flex-col items-center justify-center h-full">
          {/* Responsive media container */}
          <div className="relative mx-auto mb-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl flex-shrink-0">
           

            {isMobile === null ? null : isMobile ? (
              // Render GIF on mobile devices
              <img
                src="/images/Animation_Rendered_1.gif"
                alt="Animation"
                className="w-full h-auto mx-auto"
                style={{ maxHeight: `${windowHeight * 1.0}px` }}
              />
            ) : (
              // Render WebM video on desktop devices
              <video
                src="/images/Animation_Rendered_1.webm"
                className="w-full h-auto mx-auto"
                autoPlay
                loop
                muted
                playsInline
                style={{ maxHeight: `${windowHeight * 1.0}px` }}
              />
            )}

            {/* Radial gradient overlay */}
            <div
              className="absolute top-10 inset-0 pointer-events-none z-[-1]"
              style={{
                background:
                  "radial-gradient(circle at center, rgba(0,0,0,0.8) 35%, transparent 70%)",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>

          {/* Heading with glow effect */}
          <div className="relative inline-block mb-4">
            <h1 className="text-3xl md:text-5xl font-bold relative z-10">
              CREATE, PLAY, REMIX
            </h1>
            <div
              className="absolute inset-0"
              style={{
                background:
                  "radial-gradient(circle, rgba(255,0,255,0.7) 0%, rgba(0,0,0,0) 100%)",
                filter: "blur(15px)",
                zIndex: 0,
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>

          {/* Responsive button */}
          <div className="mt-4">
            <button
              className="bg-gradient-to-r from-purple-600 via-pink-500 to-fuchsia-700 hover:from-purple-700 hover:via-pink-600 hover:to-fuchsia-700 text-white font-bold py-2 px-6 md:py-4 md:px-10 rounded-full text-lg md:text-xl shadow-lg transform hover:scale-105 transition duration-300 ease-in-out"
              onClick={() => router.push("/waitlist")}
            >
              Join Playtest
            </button>
          </div>
        </div>

        {/* Responsive grid of animated images */}
        <div
          className="absolute inset-0 grid gap-2"
          style={{
            gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
            gridTemplateRows: `repeat(${numRows}, 1fr)`,
          }}
        >
          {gridCells.length === gridImages.length &&
            gridCells.map((cell, idx) => {
              const imageIndex = gridImages[idx];
              if (imageIndex === undefined || cell === undefined) {
                // Skip rendering this cell if data is missing
                return null;
              }
              return (
                <div
                  key={idx}
                  className="relative w-full h-full overflow-visible"
                >
                  <GridImage
                    imageIndex={imageIndex}
                    experiences={experiences}
                    wavePhase={cell.wavePhase}
                    time={time}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Hero_new;
