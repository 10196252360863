import Link from "next/link";

const Partners = () => {
  const partnerGroup = [
    { id: 3, link: "https://www.acecap.com/", logo: "partner_logo_4.png" },
    { id: 3, link: "https://corporate.comcast.com/", logo: "partner_logo_6.png" },
    { id: 3, link: "https://www.google.com/", logo: "partner_logo_8.png" },
    { id: 3, link: "https://www.nvidia.com/", logo: "partner_logo_7.png" },
    { id: 1, link: "https://www.techstars.com/", logo: "partner_logo_1.png" },
    { id: 2, link: "https://velocityincubator.com/", logo: "partner_logo_2.webp" },
    { id: 3, link: "https://www.pareto20.com/", logo: "partner_logo_3.png" },
    { id: 3, link: "https://www.siliconbadia.com/", logo: "partner_logo_5.png" },
    { id: 3, link: "https://www.archangelnetwork.ca/", logo: "partner_logo_9.png" },
  ];
  return (
    <>
      <div className="dark:bg-jacarta-900 ">
        <div className="container">
          <div className="grid grid-cols-2 py-8 sm:grid-cols-5">
            {partnerGroup.map((item) => (
              <a href={item.link} className="mx-[20px]" target="_blank" rel="noopener noreferrer" key={item.id}>
                <img src={`/images/partners/${item.logo}`} alt="partner 1" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
